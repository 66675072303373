<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <!--<div class="d-flex align-items-center row m-0" style="display:none !important;">
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-bottom: 0px;">
        Simulation dump will output a CSV of 1000 simulated draws.
      </div>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;margin-bottom: 20px;display:none !important;">
      <mdb-btn
        v-show="!spinningDrawSimulationDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Download data of all registered players on the platform."
        @click="drawSimulationDump()"
      >
        SIMULATION DUMP
      </mdb-btn>
      <mdb-btn
        v-show="spinningDrawSimulationDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Download data of all registered players on the platform."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>-->
    <div
      class="d-flex align-items-center row m-0"
    >
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-top: 0px;margin-bottom: 20px;">
        Override password to log in as any player: <span style="color:white;">2ptRo_qXg0ikbIyPCmiW1w</span>
      </div>
    </div>    
    <div
      class="d-flex align-items-center row m-0"
      style="border-top: solid 1px;"
    >
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-top: 20px;margin-bottom: 20px;">
        X login details: username: <span style="color:white;">grassrootslotto</span>, password: <span style="color:white;">grassroots123X!!</span>
      </div>
    </div>    
    <div
      class="d-flex align-items-center row m-0"
      style="border-top: solid 1px;"
    >
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-top: 20px;margin-bottom: 20px;">
        Link to Trust Payment Error Codes: <a href="https://help.trustpayments.com" target="_blank"><span style="color:white;">https://help.trustpayments.com/hc/en-us/articles/4402897300625-Error-codes</span></a>
      </div>
    </div>    
    <div
      v-if="liveStatus === 0"
      class="d-flex align-items-center row m-0"
      style="border-top: solid 1px;"
    >
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-top: 20px;">
        Add a draw: 
        <i class="fa fa-calendar white-text" />
        <input
          v-model="newDate"
          style="background-color: transparent;width: 19px;margin-left: -14px;z-index: 100000;border: none;"
          type="date"
          :min="newDate"
        >
      </div>
      <div
        v-if="newDate"
        style="text-align: left;font-size: 0.9rem;width:100%;margin-top: 20px;"
      >
        Date chosen: <span style="color:white;">{{ new Date(newDate).toLocaleDateString('en-GB') }}</span><br><br>
        <mdb-btn
          v-show="!addDrawSpinner"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
          title="Download data of all registered players on the platform."
          @click="addNewDraw()"
        >
          ADD THIS DRAW
        </mdb-btn>
        <mdb-btn
          v-show="addDrawSpinner"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          title="Download data of all registered players on the platform."
          size="sm"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </div>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'
import { LIVE_STATUS } from '@/common/config'

export default {
  name: '',
  data () {
    return {
      spinningFakeDrawDump: false,
      spinningFakeHeroImage: false,
      spinningExImage: false,
      spinningExpImage: false,
      spinningDrawSimulationDump: false,
      newDate: null,
      addDrawSpinner: false,
      newDrawData: {
        Day: null,
        Month: null,
        Year: null,
        Email: false,
        Comments: ''
      },
      liveStatus: LIVE_STATUS
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {    
    addNewDraw () {
      var newDate = new Date(this.newDate)
      var dd = newDate.getDate()
      var mm = newDate.getMonth()+1
      var yy = newDate.getFullYear()
      this.newDrawData.Day = dd;
      this.newDrawData.Month = mm;
      this.newDrawData.Year = yy;
      this.newDrawData.Email = true;
      this.newDrawData.Comments = '';
      this.addDrawSpinner = true
      baseService.addNewDraw(this.userData.access_token, this.newDrawData).then((res) => { 
        this.addDrawSpinner = false

        if (res.data) {
          this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'SUCCESS', errorMessage: 'New draw date successfully added!' })
        } else {
          this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'FAILED', errorMessage: 'Failed to add new date. Check logs but selected draw date may already exist!' })
        }

        this.newDrawData = {
          Day: null,
          Month: null,
          Year: null,
          Email: false,
          Comments: ''
        }
        this.newDate = null
      }).catch(() => {
        this.addDrawSpinner = false

        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'FAILED', errorMessage: 'Failed to add new date. Check logs but selected draw date may already exist!' })

        this.newDrawData = {
          Day: null,
          Month: null,
          Year: null,
          Email: false,
          Comments: ''
        }
        this.newDate = null
      })
    },
    fakedraw () {
      this.spinningFakeDrawDump = true
      baseService.fakedraw(this.userData.access_token).then((res) => { 
        this.spinningFakeDrawDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningFakeDrawDump = false
      })
    },
    drawSimulationDump () {
      this.spinningDrawSimulationDump = true
      baseService.drawSimulationDump(this.userData.access_token).then((res) => {
        this.spinningDrawSimulationDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningDrawSimulationDump = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .mobWidth {
    width:100% !important;
 }
 .noRightPadding {
    margin-right: 0px !important;
 }
} 
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0;
  color-scheme: dark;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 0
}
</style>
