<template>
  <div style="height: 100%;width: 100%;margin-top:20px;">
    <div
      v-if="dataLoaded"
      class="primary-colour"
      style="margin-bottom:30px;"
    >
      <div class="hideMobile">
        <i
          v-if="showLastMonthChevron"
          class="fa fa-chevron-circle-left rightChevron"
          style="cursor:pointer;"
          title="Last month"
          @click="lastMonth"
        />
        <i
          v-else
          class="fa fa-chevron-circle-left rightChevron"
          style="opacity: 0.5;"
          title="Last month"
        />
        Reconciliation for {{ desk_month }} {{ year }} 
        <i
          v-if="showNextMonthChevron"
          class="fa fa-chevron-circle-right rightChevron"
          style="cursor:pointer;"
          title="Next month"
          @click="nextMonth"
        /> 
        <i
          v-else
          class="fa fa-chevron-circle-right rightChevron"
          style="opacity: 0.5;"
          title="Next month"
        /> 
      </div>
      <div class="showMobile">
        <i
          class="fa fa-chevron-circle-left leftChevron"
          style="cursor:pointer;"
          title="Last month"
          @click="lastMonth"
        />
        {{ mob_month }} {{ year }} 
        <i
          v-if="showNextMonthChevron"
          class="fa fa-chevron-circle-right rightChevron"
          style="cursor:pointer;"
          title="Next month"
          @click="nextMonth"
        /> 
        <i
          v-else
          class="fa fa-chevron-circle-right rightChevron"
          style="opacity: 0.5;"
          title="Next month"
        /> 
      </div>
    </div>
    <BTable
      v-if="revenues.length > 0"
      id="revenues-table"
      class="text-left revenuesTable"
      head-variant="dark"
      :items="revenues"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      responsive
      :striped="true"
      hover
      small
      sort-icon-left
      auto-width
    >
      <template v-slot:cell(clubname)="data">
        <div
          v-if="windowWidth > 576"
          class="adminTableRow"
          style="margin-top:12px;margin-bottom:12px;"
        >
          {{ data.item.ClubName }}
        </div>
        <div
          v-else
          class="adminTableRow"
          style="margin-top:12px;margin-bottom:12px;"
        >
          <span class="primary-colour">Club:</span> {{ data.item.ClubName }}<br>
          <span class="primary-colour">Date:</span> {{ new Date(data.item.Month).toLocaleString('default', { year: 'numeric', month: 'short' }) }}<br>
          <span class="primary-colour">Players/Heroes:</span> {{ data.item.PlayerCount }} ({{ data.item.HeroCount }})<br>
          <span class="primary-colour">Amount:</span> £{{ data.item.Amount.toFixed(2) }}<br>
          <span class="primary-colour">Boost:</span> £{{ data.item.BoostAmount.toFixed(2) }}<br>
          <span class="primary-colour">Total:</span> £{{ data.item.TotalAmount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(month)="data">
        <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
          {{ new Date(data.item.Month).toLocaleString('default', { year: 'numeric', month: 'short' }) }}
        </div>
      </template>
      <template v-slot:cell(playercount)="data">
        <div
          class="adminTableRow"
          style="margin-top:12px;"
        >
          {{ data.item.PlayerCount }} ({{ data.item.HeroCount }})
        </div>
      </template>
      <template v-slot:cell(amount)="data">
        <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
          £{{ data.item.Amount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(boostamount)="data">
        <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
          £{{ data.item.BoostAmount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(totalamount)="data">
        <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
          £{{ data.item.TotalAmount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(ispaid)="data">
        <div class="adminTableRow" style="margin-top:12px;">
          <!-- PAID -->
          <span 
            v-if="data.item.IsPaid"
            class="payoutAdminTableStatus paid"
            :title="`paid by ${data.item.ReconciledBy} on ${new Date(data.item.DatePaid).toLocaleDateString()}`"
            style="cursor:pointer;"
          >
            <i class="fa fa-check" />
            PAID
          </span>
          <!-- DUE -->
          <div
            v-else-if="paymentIsDue(data.item.ActualMonth, data.item.Month)"
            v-show="data.item.Amount > 0"
            class="payoutAdminTableStatus due"
            :class="{'d-flex align-items-center' : data.item.Amount > 0 }"
          >
            <div
              class="primary-colour"
              style="font-size: 0.8rem !important;cursor: pointer;width:90px;"
              @click="setClubAsPaid(data.item.Month, data.item.ClubId)"
            >
              <span v-if="settingAsPaid !== data.item.ClubId">SET AS PAID?</span>
              <span v-else><i class="fa fa-spin fa-spinner" style="margin-left:20px;" /></span>
            </div>
          </div>
          <!-- ONGOING - NOT PAID BUT DATE OF PAYMENT IS IN THE FUTURE -->
          <span
            v-else
            class="payoutAdminTableStatus ongoing"
          >
            ONGOING
          </span>
          <!-- DUE -->
        </div>
      </template>
    </BTable>
    <div
      v-else
      style="margin:auto 0;text-align: center;font-size:2rem;margin-top:40px;"
      class="primary-colour"
    >
      <div
        v-if="dataLoaded"
        style="font-size: 1rem; text-align:left;margin-top:-20px;color:white;"
      >
        Sorry, there is no reconciliation data to manage for this month!
      </div>
      <div v-else>
        <i class="fa fa-spin fa-spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'
import { PAYMENT_IS_DUE } from '@/common/utils'

export default {
  name: 'ClubRevenues',
  data () {
    return {
      currentPage: 1,
      revenues: [],
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      desk_month: '',
      mob_month: '',
      year: '',
      m: null,
      showNextMonthChevron: false,
      showLastMonthChevron: true,
      settingAsPaid: 0,
      dataLoaded: false
    }
  },
  
  computed: {
    ...mapGetters([
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'ClubName', label: 'Club' },
          { key: 'Month', label: 'Payment Month' },
          { key: 'PlayerCount', label: 'Players/Heroes', sortable: true },
          { key: 'Amount', label: 'Amount' },
          { key: 'BoostAmount', label: 'Boost' },
          { key: 'TotalAmount', label: 'Total' },
          { key: 'IsPaid', label: 'Status' }
        ]
      } else {
        return [
          { key: 'ClubName', label: 'Club' },
          { key: 'IsPaid', label: 'Status' }
        ]
      }
    },
    perPage () {
      return 12
    },
    rows () {
      return 12
    }
  },
  mounted () {
    this.getReconciliationByMonth()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  created() { 
    var d = new Date();
    this.m = d.getMonth();
    if (this.m == 1 || this.m == 0) { this.desk_month = "January"; this.mob_month = "Jan"; }
    if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
    if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
    if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
    if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
    if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
    if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
    if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
    if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
    if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
    if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
    if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
    this.year = d.getFullYear();
    this.showNextMonthChevron = false
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async goBack () {
      try {
        const res = await adminService.getClubsByLetters(this.clubLetter, this.userData.access_token)
        this.clubs = res.data !== null ? res.data : []
        this.loaded = true
        this.$parent.page = 'ClubsTable'
      } catch (err) {
        this.loaded = false
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getReconciliationByMonth() {
      this.dataLoaded = false;
      try {
        const res = await adminService.getReconciliationByMonth(this.m, this.year, this.userData.access_token)
        this.settingAsPaid = 0;
        this.revenues = res.data.filter(revenue => {
          return revenue.Forecast === false
        })
        this.dataLoaded = true;
      } catch (err) {
        this.settingAsPaid = 0;
        this.dataLoaded = false;
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
    },
    paymentIsDue (paymentDate, monthDate) {
      if (monthDate <= paymentDate) {
        return PAYMENT_IS_DUE(monthDate)
      } else {
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth()
        const currentYear = currentDate.getFullYear()
        const checkDate = new Date(paymentDate)
        const paymentMonth = checkDate.getMonth()
        const paymentYear = checkDate.getFullYear()
        if (paymentMonth === currentMonth && paymentYear === currentYear) {
          return false
        } else {
          return PAYMENT_IS_DUE(paymentDate)
        }
      }
    },
    async setClubAsPaid (paymentDate, clubId) {
      this.settingAsPaid = clubId;
      try {
        var date = new Date(paymentDate);
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });
        var formattedDate = year + "-" + month + "-" + day;
        await adminService.setClubAsPaid(formattedDate, clubId, this.userData.access_token)
        this.getReconciliationByMonth()
      } catch (err) {
        this.settingAsPaid = 0;
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
    },
    nextMonth() {
      this.noData = false
      this.m = this.m + 1
      if (this.m == 13) { this.m = 1; this.year++; }
      let date = new Date()
      var month = date.getMonth()
      if (month <= this.m && this.year >= date.getFullYear()) {
        this.showNextMonthChevron = false
      }
      if (this.m >= 6 && this.year >= 2023) {
        this.showLastMonthChevron = true
      }
      if (this.m == 1) { this.desk_month = "January"; this.mob_month = "Jan"; }
      if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
      if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
      if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
      if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
      if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
      if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
      if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
      if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
      if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
      if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
      if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
      this.revenues = []
      this.getReconciliationByMonth()
    },
    lastMonth() {
      this.showNextMonthChevron = true
      this.noData = false
      this.m = this.m - 1;
      if (this.m == 0 || this.m == -1) { this.m = 12; this.year--; }
      if (this.m <= 6 && this.year <= 2023) {
        this.showLastMonthChevron = false
      }
      if (this.m == 1) { this.desk_month = "January"; this.mob_month = "Jan"; }
      if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
      if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
      if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
      if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
      if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
      if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
      if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
      if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
      if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
      if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
      if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
      this.revenues = []
      this.getReconciliationByMonth()
    }
  }
}
</script>

<style scoped>
.showMobile {
  display:none;
}
.hideMobile {
  display:block;
}
.payoutAdminTableStatus.paid {
  color: var(--pr-color);
}
.payoutAdminTableStatus.due {
  color: red;
}
.revenuesTable * {
  font-size: 0.85rem !important;
}
@media (max-width:768px) {
  .showMobile {
    display:block;
  }
  .hideMobile {
    display:none;
  }
}
@media (max-width:576px) {
  .showMobile {
    display:block;
  }
  .hideMobile {
    display:none;
  }
}
</style>
